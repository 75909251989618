import React, { Component } from "react";
import { FiUsers, FiLayers, FiSmartphone , FiGlobe , FiMonitor , FiShield } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Blockchain Consulting',
        description: 'We provide strategic advice on successful employment of decentralized tech in your organization that goes in line with your business objectives.',
        link: "/blockchain-consulting"
    },
    {
        icon: <FiLayers />,
        title: 'Fintech R&D',
        description: 'Our research and development experts will assist you at every stage of your project, from initial idea analysis to product development and support.',
        link: "/fintech-rnd"
    },
    {
        icon: <FiShield />,
        title: 'Security Audits',
        description: 'As a result of security audit our engineers will produce a report with full review of the codebase, potential issues and available solutions.',
        link: "/security-audits"
    },
    {
        icon: <FiSmartphone />,
        title: 'Financial Mobile Apps',
        description: 'Our team will help you provide your customers with secure and reliable digital payment options for their everyday life, exchange funds and pay on the go.',
        link: "/financial-mobile-apps"
    },
    {
        icon: <FiMonitor />,
        title: 'Decentralized Apps',
        description: 'Extensive experience and deep understanding of underlying technology allows us to create unstoppable applications with reliable code.',
        link: "/decentralized-apps"
    },
    {
        icon: <FiGlobe />,
        title: 'Websites And Portals',
        description: 'Our decentralized finance (DeFi) expertise allows us to build a variety of fintech solutions ranging from DEXs to peer to peer lending services.',
        link: "/websites-and-portals"
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'Full-cycle software development services designed to help you bring your business to a new level';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Get A Free Quote</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
