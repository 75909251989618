import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description0 = 'We are a small team of software craftsmen focused on writing good code and holding ourself to a very high level of quality.',
        description1 = 'Our area of expertise is cutting-edge financial technology, with a particular focus on decentralized finance and fintech. Our aim is to bridge the gap between nonsense IT terminology and experience of non-technical users in our products.',
        description2 = 'We believe that applications and financial services should not only be censorship resistant, globally accessible, trustless and permissionless, but also simple, attractive, and intuitive.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about.jpeg" alt="We are a small team of software craftsmen focused on writing good code."/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description0}</p>
                                        <p className="description">{description1}</p>
                                        <p className="description">{description2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;