import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/client-1.png" alt="Client 1 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-2.png" alt="Client 2 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-3.png" alt="Client 3 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-4.png" alt="Client 4 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-5.png" alt="Client 5 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-6.png" alt="Client 6 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-7.png" alt="Client 7 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-8.png" alt="Client 8 Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/client-9.png" alt="Client 9 Logo"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;