import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'About us';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Financial Software Development Company' 
                    pageDescription="We are at the forefront of the financial software development and our focus allows us to respond to the quickly changing fintech ecosystem through deep expertise of our human capital and use of cutting-edge technologies."/>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/financial-software-development-company.png" alt="Financial software development company"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2 className="title">{title}</h2>
                                                    <p>Nonsense Technologies is a full-cycle boutique software development agency with its headquarters based in London, UK and development centre based in Minsk, Belarus. We are at the forefront of the financial software development and our focus allows us to respond to the quickly changing fintech ecosystem through deep expertise of our human capital and use of cutting-edge technologies.</p>
                                                    <p>The use of proven methodologies and practices by our team of business analysts, project managers and software engineers, secures the success of any project over all stages of the development process. Our skills and knowledge help us to build highly intuitive and entertaining applications leveraging the latest trends in the financial industry.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>We provide end-to-end custom software development services, taking into account the needs and business processes of our clients and offering high quality solutions for their IT needs. Our fintech software development company provides a full range of web and application development services for all platforms.</p>
                                                    <p>We believe that our expertise and skills can provide a solid base for the successful creation of any kind of mobile/web app or custom software.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/custom-software-development-services.png" alt="Custom software development services"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">How To Get Started</h4>
                                <p>Every project starts with an idea which must be thoroughly thought over and brainstormed in order to meet your business goals as well as the requirements of current trends.<br /><br />At this stage our experts meet with you to understand your goals and requirements and to propose solutions which will suit your needs and make your app or a platform stand out from competitors.</p>
                                <a className="rn-btn btn-white" href="/contact">Get In Touch</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/how-to-get-started.png" alt="How To Get Started with your financial app"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About