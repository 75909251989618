// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// My Pages
import HomePage from './pages/HomePage';
import WhatWeDo from "./pages/WhatWeDo";
import About from "./pages/About";
import Contact from "./pages/Contact";

import BlockchainConsulting from "./pages/services/BlockchainConsulting";
import FintechRND from "./pages/services/FintechRND";
import SecurityAudits from "./pages/services/SecurityAudits";
import FinancialMobileApps from "./pages/services/FinancialMobileApps";
import DecentralizedApps from "./pages/services/DecentralizedApps";
import WebsitesAndPortals from "./pages/services/WebsitesAndPortals";

import error404 from "./elements/error404";

// import BlogDetails from "./elements/BlogDetails";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/what-we-do`} component={WhatWeDo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/blockchain-consulting`} component={BlockchainConsulting}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/fintech-rnd`} component={FintechRND}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/security-audits`} component={SecurityAudits}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/financial-mobile-apps`} component={FinancialMobileApps}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/decentralized-apps`} component={DecentralizedApps}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/websites-and-portals`} component={WebsitesAndPortals}/>


                    {/* <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/> */}

                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
