import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiUsers, FiLayers, FiSmartphone , FiGlobe , FiMonitor , FiShield } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList1 = [
    {
        icon: <FiUsers />,
        title: 'Blockchain Consulting',
        description: 'We provide strategic advice on successful employment of decentralized tech in your organization that goes in line with your business objectives.',
        link: "/blockchain-consulting"
    },
    {
        icon: <FiLayers />,
        title: 'Fintech R&D',
        description: 'Our research and development experts will assist you at every stage of your project, from initial idea analysis to product development and support.',
        link: "/fintech-rnd"
    },
    {
        icon: <FiShield />,
        title: 'Security Audits',
        description: 'As a result of security audit our engineers will produce a report with full review of the codebase, potential issues and available solutions.',
        link: "/security-audits"
    },
]

const ServiceList2 = [
    {
        icon: <FiSmartphone />,
        title: 'Financial Mobile Apps',
        description: 'Our team will help you provide your customers with secure and reliable digital payment options for their everyday life, exchange funds and pay on the go.',
        link: "/financial-mobile-apps"
    },
    {
        icon: <FiMonitor />,
        title: 'Decentralized Apps',
        description: 'Extensive experience and deep understanding of underlying technology allows us to create unstoppable applications with reliable code.',
        link: "/decentralized-apps"
    },
    {
        icon: <FiGlobe />,
        title: 'Websites And Portals',
        description: 'Our decentralized finance (DeFi) expertise allows us to build a variety of fintech solutions ranging from DEXs to peer to peer lending services.',
        link: "/websites-and-portals"
    },
]
class WhatWeDo extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Bespoke Software Development Services & IT Consulting' 
                    pageDescription="We offer comprehensive IT consulting and bespoke software development services designed to help you bring your business to a new level. We provide strategic advice on successful employment of decentralized tech in your organization."/>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'What we do'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>Comprehensive IT consulting aimed at smooth <br /> financial transformation of your business.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList1.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>Full-cycle software development services <br /> designed to help you bring your business to a new level.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList2.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default WhatWeDo;